<template>
<section class="container">

    <div class="has-text-centered">

    <div class="title">
        Store Name
    </div>
        <p  v-if="tankInventory.length > 0" class="is-size-4 has-text-weight-bold">CURRENT FUEL INVENTORY</p>
    <hr>
    </div>

    <div class="columns is-desktop">

        <div class="column box has-text-centered" v-if="tankInventory.length == 0">
            <p class="title">Unauthorized</p>
        </div>
        <div class="column box has-text-centered" v-for="item in tankInventory" :key="item.tankName">
            <div>
                <p class="heading is-size-5">Tank: {{ item.tankName }} <br> Product: {{ item.product }}</p>
                <!-- <p class="is-size-4 has-text-weight-bold">{{ item.volAvailablePercentage }}%</p> -->
                <p class="is-size-4 has-text-weight-bold">{{ item.volume | formatFuelVolumeSimple  }} gal </p>
                <b-progress type="is-success" show-value :value="item.volAvailablePercentage" format="percent"></b-progress>
            </div>

        </div>
    </div>
</section>
</template>

<script>

import axios from 'axios'

export default {
    metaInfo: {
        title: 'Current Fuel Inventory'
    },

    data() { 
        return {
            tankInventory: [],
            dataNotAvailable: false
        }
    },

    methods: {


        fetchTankInventory() {

            var tanks = []

            if ( !this.$route.query.key || !this.$route.query.partner) {
                console.log("Unauthorized")
                return tanks
            }
            // console.log("Authorized")

            var partner = "petrodatasync.com"
            var key = this.$route.query.key
            var url = process.env.VUE_APP_API_ROOT_URL + "partner/tankinventory?key=" + key + "&partner=" + partner

            axios.get(url)
                .then( (response) => {

                    // console.log(response)

                    if (response.data) {

                        this.tankInventory = response.data

                    } else {
                        this.tankInventory = []
                        this.dataNotAvailable = true
                    }

                })
                .catch( (err) => {
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
                    this.isLoading = false
                })        

        },

    },

    mounted() {
        this.fetchTankInventory()
    }

}


</script>
